import {POPUP_ACTIONS} from './ACTION_TYPES';
import {popUpState} from '../reducer/PopUpReducer';

export const POPUP_TYPES = {
  NEW_CODE: 'popup get new login code',
};

export const handleOpen = (state: boolean) => (
    dispatch: any, getState: () => { popUp: popUpState }) => {
  state ?
      dispatch({
        type: POPUP_ACTIONS.SET_OPEN,
      }) :
      dispatch({
        type: POPUP_ACTIONS.SET_CLOSE,
      });
};

export const handlePopUp = (popUp: string) => (
    dispatch: any, getState: () => { popUp: popUpState }) => {
  handleOpen(true);
  dispatch({
    type: POPUP_ACTIONS.SET_CONTENT,
    payload: popUp,
  });
  dispatch({
    type: POPUP_ACTIONS.SET_OPEN,
  });
};
