export const POPUP_ACTIONS = {
  SET_OPEN: 'pop up set open',
  SET_CLOSE: 'pop up set close',
  SET_CONTENT: 'pop up set content',
}

export const UI_ACTIONS = {
  SET_MESSAGE: 'ui set msg',
  CLEAR_MESSAGES: 'ui clear msgs',
}

