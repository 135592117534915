import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import {Button, Grid, Container} from '@mui/material';
import LoginSrc from './home.png';
import BefundSrc from './bilder.png';

export default function BefundFAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      };

  return (
      <Container className={'faqSection'}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant={'h4'} variantMapping={{'h4': 'h2'}}
                        sx={{color: '#ffffff', m: 0}}>Befund</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <div>
                <Accordion expanded={expanded === 'panel4'}
                           onChange={handleChange('panel4')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wie kann ich einen Befund ausdrucken?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Das Ausdrucken ist nur bei Nutzung eines Computers
                      möglich. Wenn ein Befund im
                      Befundportal vorliegt, wird dieser an zwei Stellen
                      angezeigt. Einmal links unter
                      dem Reiter Dokumente (hier als reiner Text ohne Logo und
                      Briefkopf) und einmal
                      bei den Bildern. Bei jeder Untersuchung ist in der letzten
                      Serie der Befund als
                      PDF-Datei eingebettet. Klickt man mit der Maus in den
                      eingebetteten Befund,
                      erscheint oben eine Werkzeugleiste. Die letzte
                      Schaltfläche auf dieser
                      Werkzeugleiste ist ein Drucker-Symbol, über den der Befund
                      ausgedruckt werden
                      kann. Der Befund kann gespeichert werden, indem man einen
                      PDF-Drucker auswählt.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel1'}
                           onChange={handleChange('panel1')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Ich kann die Bilder sehen, aber wo ist der Befund?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Der Befund ist noch nicht fertiggestellt. Sobald der Arzt
                      den Befund freigibt,
                      erscheint er auch im Portal. Unabhängig vom Portal wird
                      ihrem überweisenden Arzt
                      der Befund übermittelt.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'}
                           onChange={handleChange('panel2')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wie kommt mein Arzt an Befund und Bilder?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Der Arzt bekommt den Befund und ggf. auch die Bilder
                      unabhängig von diesem Portal. Sie können dem Arzt aber
                      Ihren
                      Portalzugang geben, wenn Sie möchten,
                      eventuell enthält er Voraufnahmen und Vorbefunde. Ihr Arzt
                      benötigt neben dem
                      Portalzugang auch Ihr Geburtsdatum. Manche Ärzte haben
                      auch einen eigenen
                      Portalzugang für ihre Patientinnen und Patienten.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <div>
                <Accordion expanded={expanded === 'panel6'}
                           onChange={handleChange('panel6')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wo finde ich die Befunde zu den Untersuchungen?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <img src={BefundSrc}/>
                    <Typography>
                      Auf dem PC finden Sie oben links den Button Dokumente.
                      Dort werden
                      Ihnen die Befunde zu den
                      jeweiligen Untersuchungen angezeigt. Zusätzlich finden Sie
                      den Befund bei den
                      Bildern. Bei jeder Untersuchung ist in der letzten Serie
                      der Befund als
                      PDF-Datei eingebettet
                    </Typography>
                  </AccordionDetails>
                </Accordion><Accordion expanded={expanded === 'panel7'}
                                       onChange={handleChange('panel7')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                  <Typography sx={{flexShrink: 0}}>
                    Sind meine Daten sicher?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Typography>
                    Ihre Daten werden auf unseren eigenen Servern
                    sicher gespeichert. Wir geben Ihre Daten nicht weiter und
                    arbeiten
                    komplett konform mit der DSGVO. Das verlangen wir auch von
                    all
                    unseren
                    Partnern. Für Ihre Sicherheit – und die Ihrer Daten.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Container>
  );
}