import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import {Button, Grid, Container, Box} from '@mui/material';
import homeImg from './home.png';
import dateVideo from './Anleitung.mp4';
import {handlePopUp} from '../actions/popupActions';
import {useDispatch} from 'react-redux';
import {POPUP_TYPES} from '../actions/popupActions';
//@ts-ignore
import BefundAnleitung from '../statics/Befundportal_Anleitung.pdf';

export default function LoginFAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const dispatch = useDispatch();

  const handleNewCodeForm = () => {
    dispatch(handlePopUp(POPUP_TYPES.NEW_CODE));
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.download = 'kurzanleitung_patienteportal.pdf';
    link.href = BefundAnleitung;
    link.click();
  };

  const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      };

  return (
      <Container className={'faqSection'}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant={'h4'} variantMapping={{'h4': 'h2'}}
                        sx={{color: '#ffffff', m: 0}}>Zugang /
              Login</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <div>
                <Accordion expanded={expanded === 'panel4'}
                           onChange={handleChange('panel4')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wo finde ich den Login?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Button variant={'contained'} color={'secondary'}
                            href={'https://portal-login.rh-dt.de/'}>
                      zum Patienten-Login
                    </Button>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel1'}
                           onChange={handleChange('panel1')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wie erhalte ich meine persönlichen Zugangsdaten?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Als Patient/Patientin erhalten Sie vor oder nach der
                      Untersuchung einen Ausdruck mit Ihrem Zugangscode.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'}
                           onChange={handleChange('panel2')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Was kann ich tun, wenn ich meinen Code nicht mehr finde?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Sie erhalten einen neuen Zugangscode, wenn Sie das
                      Formular abschicken.
                    </Typography>
                    <Button variant={'contained'} color="secondary"
                            onClick={handleNewCodeForm}
                            sx={{my: 3}}>
                      Zum Formular
                    </Button>

                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel7'}
                           onChange={handleChange('panel7')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Gibt es eine Bedieungsanleitung?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Unsere Kurzanleitung kann Ihnen über die ersten Schritte
                      in der Bedienung des Portals helfen, so dass Sie in der
                      Lage sind, die Bilder und Befunde von allen Ihren
                      Untersuchungen zu sehen, die in unserem Archiv gespeichert
                      sind.


                    </Typography>
                    <Box>
                      <Button variant={'contained'} color="secondary"
                              onClick={handleDownload}
                              sx={{my: 3}}>
                        Kurzanleitung herunterladen
                      </Button>
                    </Box>
                    Eine weiterführende und sehr ausführliche
                    Bedienungsanleitung, die sich mehr an den professionellen
                    Nutzer wendet, finden Sie im Portal, indem Sie oben rechts
                    neben dem stilisierten Portraitsymbol auf Ihre
                    Patienten-ID klicken oder tippen und dann aus dem
                    Kontext-Menu „Hilfe“ auswählen.

                  </AccordionDetails>
                </Accordion>
              </div>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <div>
                <Accordion expanded={expanded === 'panel3'}
                           onChange={handleChange('panel3')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>Was sehe ich nach dem
                      Login?</Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <img src={homeImg}/>
                    <Typography>
                      Die Darstellung ist je nach Gerätetyp (PC, Tablet,
                      Smartphone) verschieden. Auf jeden Fall sehen Sie zuerst
                      das erste Bild oder die erste Bilderserie der jüngsten
                      Untersuchung. Je nach Gerät nutzen Sie die angezeigten
                      Steuerelemente (Menu-Symbole, Pfeile), um zu Ihrem Befund
                      oder früheren Untersuchungen zu navigieren.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel5'}
                           onChange={handleChange('panel5')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Was passiert mit meinen Bildern und dem Zugang nach sechs
                      Monaten?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>Der Zugang über dem QR-Code läuft nach sechs
                      Monaten ab. Das MVZ
                      archiviert die Daten aber mindestens 10 Jahre. Sie können
                      sich jederzeit im MVZ einen neuen QR-Code abholen. Die
                      alten Bilder sind
                      mit einem neuen Code
                      auch
                      aufrufbar.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'}
                           onChange={handleChange('panel6')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wie gebe ich mein Geburtsdatum ein?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <video src={dateVideo} controls/>
                    <Typography>
                      Der Kalender von den Browsern mobiler Betriebssysteme
                      macht das Navigieren
                      des
                      Geburtsdatums auf den ersten Blick schwer.
                      <br/>
                      <strong>Lösung iOS: </strong> Es kann oben im Kalender auf
                      den Monat
                      geklickt
                      und anschließend
                      das Jahr und der Monat über ein Rad einfach ausgewählt
                      werden.
                      <br/>
                      <strong>Lösung Android:</strong> Es kann oben über dem
                      Kalender auf das Jahr
                      geklickt
                      werden und anschließend kann einfach in das richtige Jahr
                      navigiert werden,
                      anschließend der Monat und dann der Tag.
                    </Typography>
                    <Typography>
                      Im Video sehen Sie, wie Sie Ihr Geburtsdatum bei Android-
                      und bei
                      Apple/iOS-Geräten eingeben können.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Container>
  );
}