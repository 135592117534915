export interface inputEl {
  type?: string,
  value: string,
  error?: boolean,
  required?: boolean,
}

export class Validator {
  /**
   * Regex-Pattern
   */
  static EMAIL = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  static STRING = /^[a-zA-ZäöüÄÖÜ ]{3,}$/;
  static TEXT = /^[a-zA-ZäöüÄÖÜ\W]{3,}$/;
  static DATE = /^(0[1-9]|[1-2][0-9]|3[0-2]).(0[1-9]|1[0-2]).([1-2][0-9]{3})$/;
  static NUMBER = /^[\d ]{8,20}$/;

  static validateEmail(inputEl: inputEl): inputEl {
    if (!inputEl.required && inputEl.value.length === 0) {
      return this.handleInput(true, inputEl);
    }
    return this.handleInput(this.EMAIL.test(inputEl.value), inputEl);
  }

  static validateNumber(inputEl: inputEl): inputEl {
    if (!inputEl.required && inputEl.value.length === 0) {
      return this.handleInput(true, inputEl);
    }
    return this.handleInput(this.NUMBER.test(inputEl.value), inputEl);
  }

  static validateDate(inputEl: inputEl): inputEl {
    if (!inputEl.required && inputEl.value.length === 0) {
      return this.handleInput(true, inputEl);
    }
    return this.handleInput(this.DATE.test(inputEl.value), inputEl);
  }

  static validateTextArea(inputEl: inputEl): inputEl {
    if (!inputEl.required && inputEl.value.length === 0) {
      return this.handleInput(true, inputEl);
    }
    return this.handleInput(
        this.TEXT.test(inputEl.value) && inputEl.value.length !== 0, inputEl);
  }

  static validateText(inputEl: inputEl): inputEl {
    if (!inputEl.required && inputEl.value.length === 0) {
      return this.handleInput(true, inputEl);
    }
    return this.handleInput(this.STRING.test(inputEl.value), inputEl);
  }

  static handleInput(match: boolean, inputEl: inputEl): inputEl {
    const inputReturn = {
      ...inputEl,
      error: !match,
    };
    return inputReturn;
  }
}