import {UI_ACTIONS} from './ACTION_TYPES'
import {uiMessage} from '../reducer/UiReducer'

export const handleErrorMsg = (message: string) => (dispatch: any) => {
  dispatch({
        type: UI_ACTIONS.SET_MESSAGE,
        payload: {
          type: 'error',
          content: message,
        },
      },
  )
}

export const handleInfoMsg = (message: string) => (dispatch: any) => {
  dispatch({
        type: UI_ACTIONS.SET_MESSAGE,
        payload: {
          type: 'info',
          content: message,
        },
      },
  )
}
export const handleSuccessMsg = (message: string) => (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_MESSAGE,
    payload: {
      type: 'success',
      content: message,
    },
  })
}

export const clearMsg = () => (dispatch: any) => {
  dispatch({type: UI_ACTIONS.CLEAR_MESSAGES})
}

export const handleMsg = (uiMessage: uiMessage) => (dispatch: any) => {
  dispatch({
    type: UI_ACTIONS.SET_MESSAGE,
    payload: uiMessage,
  })
}