import React from 'react'
import {Button} from '@mui/material'
import {getLoginLink} from '../../lib/links'

interface LoginButton {
  name?: String,
  variant?: 'contained' | 'login',
  target?: '_blank' | '_self' | '_top' | '_parent'
  code?: String,
}

export default function LoginButton({
                                      name = 'Hier anmelden',
                                      target = '_blank',
                                      code = '',
                                      variant = 'contained',
                                    }: LoginButton) {
  return (
      <Button variant={variant}
              href={getLoginLink()}
              target={target} color={'secondary'} sx={{my: 3}}>
        {name}
      </Button>
  )

}