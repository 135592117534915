import React from 'react';
import {
  Container,
  Grid,
  Typography,
  Button,
  styled,
  Stack, Box,
} from '@mui/material';
import {ArrowForward, Download, Help, HelpCenter} from '@mui/icons-material';
import {HashLink} from 'react-router-hash-link';
import LoginButton from './shared/LoginButton';
//@ts-ignore
import BefundAnleitung from '../statics/Befundportal_Anleitung_11_22.pdf';

const LoginGrid = styled(Grid)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: theme.spacing(4),
  paddingTop: theme.spacing(4),
}));

const LoginGridItem = styled(Grid)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  color: '#fff',
  height: '100%',
  padding: theme.spacing(4),
  background: theme.palette.primary.main,
}));

export default function Login({...props}: any) {

  const handleDownload = () => {
    const link = document.createElement('a');
    link.download = 'kurzanleitung_patienteportal.pdf';
    link.href = BefundAnleitung;
    link.click();
  };

  return (
      <section id={'login'}>
        <Grid container spacing={0} className={'firstView'}
              alignItems="flex-end">
          <Grid item xs={12} md={4} xl={3} className={'contentContainer'}>
            <Container sx={{mt: {xs: 12, md: 0}}}>
              <Typography
                  variant={'h1'}>Ihr <strong>digitaler</strong> Befund</Typography>
              <Typography variant={'body1'}>
                Ihr Zugriff auf Bilder und Untersuchungsdaten. Sicher, einfach,
                jederzeit. Mit voller
                Kontrolle über Ihre Daten. Zeitgemäß und nachhaltig.
              </Typography>
              <Button variant={'text'} endIcon={<ArrowForward/>} sx={{my: 2}}>
                <HashLink to={'#faq'} smooth
                          style={{textDecoration: 'none', color: 'inherit'}}>
                  Häufige Fragen
                </HashLink>
              </Button>
            </Container>
          </Grid>
          <LoginGrid item container spacing={0} xs={12} md={8} xl={9}
                     alignItems={'stretch'}
                     sx={{height: {md: '100vh'}, padding: {md: 0}}}>
            <LoginGridItem item className={'loginPatient'}>
              <Typography variant={'buttonTopline'}>
                Ihr Zugang als
              </Typography>
              <LoginButton name={'Patient/In'} variant={'login'}/>
              <Typography sx={{maxWidth: '390px'}} textAlign={'center'}>
                Zugang zu Ihren Bildern und Befunden. Login mit Zugangscode und
                Ihrem Geburtsdatum. Den Zugangscode haben Sie nach Ihrer
                Untersuchung erhalten.
              </Typography>
              <Stack spacing={3} direction={{xs: 'column', md: 'row'}} my={3}
                     justifyContent={'center'} alignItems={'center'}>
                <Box>
                  <Button variant={'contained'} endIcon={<Download/>}
                          onClick={handleDownload}
                          color={'secondary'}>Kurzanleitung</Button>
                </Box>
                <Box>
                  <Button
                      variant={'outlined'} endIcon={<Help/>}
                      href={'#faq'}
                      color={'secondary'}>Häufige Fragen</Button>
                </Box>
                <Box>
                  <Button color={'secondary'} href={'#zugangscode'}
                          endIcon={<ArrowForward/>}
                  >Zugangscode verloren?</Button>
                </Box>
              </Stack>
            </LoginGridItem>
          </LoginGrid>
        </Grid>
      </section>
  );
}