import {Box, Button, Container, Stack, styled} from '@mui/material';
import {getLocation, getLocationData} from '../../lib/locations';
import {Phone} from '@mui/icons-material';
import {useLocation} from 'react-router-dom';

const CustomHeader = styled('header')(({theme}) => ({
  //background: theme.palette.primary.dark,
  // backdropFilter: 'blur(50px)',
  background: '#fff',
  boxShadow: '5px 0 10px #0d0d0d',
  padding: theme.spacing(2, 0),
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 20,
}));

const HeaderContainer = styled('div')(({theme}) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
}));

const Header = () => {


  const routerLocation = useLocation();
  const location = routerLocation.pathname.replaceAll('/', '');
  const data = getLocationData(location);


  console.log('DATA :' ,data);

  if (!data) {
    return null;
  }

  return (
      <CustomHeader
          // // @ts-ignore
          // sx={data.options.customHeaderColor !== null ?
          //     // @ts-ignore
          //     {background: data.options.customHeaderColor} :
          //     {}}
      >
        <HeaderContainer>

          <Box sx={{maxWidth: 'min(50%,100px)', maxHeight: 'min(50%,50px)'}}>
            <img src={data.logo} alt="" style={{objectFit: 'contain'}}/>
          </Box>

          <Box sx={{width: '50%'}}>
            <Stack spacing={3} direction={'row'} justifyContent={'flex-end'}>
              <Box>
                <Button variant={'outlined'} color={'secondary'}
                        href={'tel:' + data.tel.link}
                        sx={{display: {xs: 'none', md: 'block'}}}>
                  {data.tel.text}
                </Button>
              </Box>
              <Box>
                <Button variant={'contained'} color={'secondary'}
                        href={data.link}
                        target={'_blank'}>
                  Zurück zur Webseite
                </Button>
              </Box>
            </Stack>
          </Box>
        </HeaderContainer>

      </CustomHeader>
  );
};

export default Header;