import PopUp from '../popups/PopUp';
import UiMessages from '../ui/uiMessages';
import Login from '../Login';
import FAQ from '../FAQ';
import NewCode from '../NewCode';
import React, {useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {getLocation} from '../../lib/locations';
import {getTheme} from '../../lib/getTheme';
import Header from '../shared/Header';

const Home = () => {

  const loginRef = useRef('login');
  const faqRef = useRef('faq');
  const containerRef = useRef('container');

  const routerLocation = useLocation();
  const location = routerLocation.pathname.replaceAll('/', '');
  const theme = getTheme(location);
  console.log('Router location : ', routerLocation,location);

  return (
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        {location && (<Header/>)}
        <PopUp/>
        <UiMessages/>
        {/*//@ts-ignore*/}
        <div ref={containerRef}>
          <Login ref={loginRef}
                 key={loginRef}/>
          <FAQ ref={faqRef}
               key={faqRef}/>
          <NewCode/>
        </div>
      </ThemeProvider>);
};
export default Home;