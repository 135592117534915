import {getLocation, getParams} from './locations'

export const getLink = (link: string, options = {withParams: true}) => {
  if (!options?.withParams) {
    return link
  }
  const code = getParams().get('code')
  const location = getLocation()
  let paramAdded = false

  const locationParam = location === null ? '' : `location=${location}`
  const codeParam = code === null ? '' : `code=${code}`
  if (locationParam.length > 0) {
    link += '?' + locationParam
    paramAdded = true
  }
  if (codeParam.length > 0) {

    if (paramAdded) {
      link += '&' + codeParam
    }
    else {
      link += '?' + codeParam
    }
  }
  return link
}

export const getLoginLink = () => {
  const defaultLink = 'https://portal-login.rh-dt.de'
  const link = getLink(defaultLink)
  return link
}