import {createTheme, responsiveFontSizes} from '@mui/material'

const defaultPrimaryColor = '#1c1e26'
const defaultSecondaryColor = '#CAAA56'

export const defaultTheme = responsiveFontSizes(createTheme({
  typography: {
    h1: {
      color: defaultPrimaryColor,
    },
    h2: {
      color: defaultSecondaryColor,
    },
    h3: {
      color: '#fff',
    },
    topline: {
      color: defaultPrimaryColor,
      fontWeight: 'bold',
    },
    buttonTopline: {
      color: '#ffffff',
      fontWeight: 300,
      fontSize: '3.75rem',
    },

  },
  palette: {
    primary: {
      main: defaultPrimaryColor,
    },
    secondary: {
      main: defaultSecondaryColor,
    },
    success: {
      main: '#ffffff',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h3: {
          color: '#ffffff',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: {variant: 'login'},
          style: {
            color: '#fff',
            background: 'none',
            border: '1px solid #fff',
            fontSize: 64,
          },
        },
      ],
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: 'none',
          color: '#ffffff',
        },
      },
    },
    MuiAccordionActions: {
      styleOverrides:
          {
            root:
                {
                  color: '#ffffff',
                },
          },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          borderColor: '#ffffff',
        },
        expandIconWrapper: {
          color: '#ffffff',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#ffffff',
        },
      },
    },
  },
}))
