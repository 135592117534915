import axios from 'axios'
import {valuesState} from '../components/forms/NewCodeForm'

const api = axios.create({
  baseURL: 'https://api.rh-it.de/api/v1/',
})

export const sendNewCodeForm = async (data: {}) => {
  return await api.post('form/newcode', data)
}

export const sendContactForm = async (data: {}) => {
  return await api.post('form/contact', data)
}