import React from 'react';
import {Modal, Paper, Container, Button} from '@mui/material';
import {Close} from '@mui/icons-material';
import './PopUp.scss';
import NewCodeForm from '../forms/NewCodeForm';
import {useDispatch, useSelector} from 'react-redux';
import {handleOpen, POPUP_TYPES} from '../../actions/popupActions';

export default function PopUp() {
  const popUpState: any = useSelector<{ PopUp: { open: boolean, popUp: String } }>(
      state => state.PopUp);
  const {open, popUp} = popUpState;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
        handleOpen(false),
    );
  };

  const getPopUp = () => {
    if (open) {
      switch (popUp) {
        case POPUP_TYPES.NEW_CODE:
          return <NewCodeForm/>;
      }
    }
    else {
      return '';
    }
  };

  return (
      <Modal open={open} onClose={handleClose} className={'popUp'}>
        <Paper className={'wrapper'} elevation={3}>
          <Container className={'close'}>
            <Button endIcon={<Close/>}
                    onClick={handleClose}>Schließen</Button> </Container>
          <Container
              className={'content'}> {getPopUp()} </Container> </Paper>
      </Modal>);
}