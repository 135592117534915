import React, {useState, FocusEvent} from 'react';
import {
  Container, FormControl, FormControlLabel,
  Grid, RadioGroup, Radio,
  FormGroup, Typography, TextField,
  Button, Checkbox, FormLabel, Select, MenuItem,
} from '@mui/material';
import {Send} from '@mui/icons-material';
import {inputEl, Validator} from '../../helper/Validator';
import {sendNewCodeForm} from '../../helper/api';
import {useDispatch} from 'react-redux';
import {handleOpen} from '../../actions/popupActions';
import {handleSuccessMsg, handleErrorMsg} from '../../actions/uiActions';
import {
  locationEmpty,
  getLocation,
  locations, getLocationData,
} from '../../lib/locations';
import {useLocation} from 'react-router-dom';

export interface valuesState {
  firstname: inputEl,
  lastname: inputEl,
  birthdate: inputEl,
  number: inputEl,
  email: inputEl,
}

const initialValues: valuesState = {
  'firstname': {value: ''},
  'lastname': {value: ''},
  'birthdate': {value: ''},
  'number': {value: '', required: false},
  'email': {value: ''},
};

export default function NewCodeForm({options = {my: 5}}: { options?: any }) {
  const [privacy, setPrivacy] = useState({
    checked: false,
    error: false,
  });

  const routerLocation = useLocation();
  const location = routerLocation.pathname.replaceAll('/', '');
  const temp = getLocationData(location);
  const initLocationGiven = routerLocation.pathname !== '/';

  const [values, setValues] = useState<valuesState>(initialValues);
  const [currentLocation, setCurrentLocation] = useState<string | null>(
      temp ? temp.email : null,
  );
  const [locationError, setLocationError] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('newcode');

  const dispatch = useDispatch();

  const handleSubmit = () => {
    let submitting = true;
    for (const [key, inputEl] of Object.entries(values)) {
      handleValidation(key);
      if (inputEl.error === true) {
        submitting = false;
      }
    }
    if (currentLocation === null) {
      setLocationError(true);
      submitting = false;
    }
    if (!privacy.checked) {
      setPrivacy({
        ...privacy,
        error: true,
      });
      submitting = false;
    }
    if (submitting) {
      const data = {
        place: currentLocation,
      };
      Object.keys(values).
          forEach((key: string) => {
            // @ts-ignore
            data[key] = values[(key as keyof valuesState)].value;
          });
      sendNewCodeForm({...data, message, messageType}).then(res => {
        if (res.status === 200) {

          setValues(initialValues);
          dispatch(handleOpen(false));
          dispatch(handleSuccessMsg('Vielen Dank für Ihre Anfrage. Sie haben' +
              ' eine Bestätigungs-Email von uns erhalten. Bitte überprüfen' +
              ' Sie ebenfalls Ihren Spam-Ordner.'));

        }
      }).catch(e => {
        console.error(e);
      });
    }

  };

  const handleMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleMessageType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageType(event.target.value);
  };

  const handleChange = (prop: keyof valuesState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handleValue(prop, {value: event.target.value});
  };

  const handleValue = (prop: keyof valuesState, inputEl: inputEl) => {
    setValues({
      ...values,
      [prop]: {
        ...values[prop],
        ...inputEl,
      },
    });
  };

  const handleValidation = (prop: keyof valuesState | string) => {
    //@ts-ignore
    if (!values[prop].required && values[prop].value.length === 0) {
      //@ts-ignore
      return {...values[prop], error: false};
    }
    switch (prop) {
      case 'firstname':
      case 'lastname':
        return handleValue(prop, Validator.validateText(values[prop]));
      case 'birthdate':
        return handleValue(prop, Validator.validateDate(values[prop]));
      case 'email':
        return handleValue(prop, Validator.validateEmail(values[prop]));
      case 'number':
        return handleValue(prop, Validator.validateNumber(values[prop]));
    }
  };

  const handleFocusLeave = (prop: keyof valuesState) => (event: FocusEvent<HTMLInputElement>) => {
    if (!event.target.required && values[prop].value.length === 0) {
      setValues({
        ...values,
        [prop]: {
          ...values[prop],
          error: false,
        },
      });
    }
    else {
      handleValidation(prop);
    }

  };

  const handleSelect = (event: any) => {
    setLocationError(false);
    setCurrentLocation(event.target.value);
  };

  const handleCheck = () => {
    setPrivacy(privacy => ({
      checked: !privacy.checked,
      error: privacy.checked,
    }));
  };

  const displayedLocations = locations.sort(
      (a, b) => a.clearName.localeCompare(b.clearName));

  return (
      <Container sx={{my: options.my}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant={'h4'} component={'h3'}>
              Ihr Kontakt zu uns
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>
              Bitte beachten Sie: Aus Gründen des Datenschutzes können wir
              Ihre neuen Zugangsdaten ausschließlich postalisch zustellen.
            </Typography>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Typography variant={'h6'}>Ich habe (bitte
                auswählen):</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup value={messageType} onChange={handleMessageType}>
                  <FormControlLabel control={<Radio/>} value={'newcode'}
                                    label={'meinen' +
                                    ' Zugangscode verloren und benötige einen neuen.'}/>
                  <FormControlLabel control={<Radio/>} label={'Fragen zur' +
                  ' Bedienung des Portales'} value={'message'}/>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Typography variant={'h6'} component={'h3'}>Persönliche
                Angaben</Typography>
            </Grid>

            <Grid item md={6}>
              <TextField fullWidth name={'firstname'}
                         error={values.firstname.error}
                         value={values.firstname.value}
                         onBlur={handleFocusLeave('firstname')}
                         onChange={handleChange('firstname')}
                         label={'Vorname'} required/>
            </Grid>
            <Grid item md={6}>
              <TextField fullWidth name={'lastname'}
                         value={values.lastname.value}
                         error={values.lastname.error}
                         onBlur={handleFocusLeave('lastname')}
                         onChange={handleChange('lastname')}
                         label={'Nachname'} required/>
            </Grid>
            <Grid item md={4}>
              <TextField fullWidth name={'birthdate'}
                         error={values.birthdate.error}
                         onBlur={handleFocusLeave('birthdate')}
                         value={values.birthdate.value}
                         onChange={handleChange('birthdate')}
                         placeholder={'TT.MM.YYYY'}
                         label={'Geburtsdatum'} required/>
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth multiline rows={4} label={'Nachricht'}
                         value={message} onChange={handleMessage}/>
            </Grid>
          </Grid>
          {!initLocationGiven && (
              <Grid item xs={12}>
                <FormControl fullWidth error={locationError}>
                  <Typography variant={'h6'} component={'h4'}>
                    Bitte wählen Sie ihr MVZ aus*
                  </Typography>
                  <Select onChange={handleSelect}
                          value={currentLocation}
                  >
                    {displayedLocations.map(location => (
                        <MenuItem key={location.clearName}
                                  value={location.email}>{location.clearName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
          )}

          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Typography variant={'h6'} component={'h3'}>
                Für Rückfragen
              </Typography>
            </Grid>
            <Grid item md={4}>
              <TextField fullWidth name={'number'}
                         label={'Telefonnummer'}
                         onBlur={handleFocusLeave('number')}
                         error={values.number.error}
                         value={values.number.value} type={'tel'}
                         onChange={handleChange('number')}/>
            </Grid>
            <Grid item md={4}>
              <TextField fullWidth required name={'email'} type={'email'}
                         onBlur={handleFocusLeave('email')}
                         error={values.email.error}
                         label={'E-Mail' +
                         ' Adresse'} value={values.email.value}
                         onChange={handleChange('email')}/>
            </Grid>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Typography variant={'h6'} component={'h3'}>
                Datenschutz
              </Typography>
            </Grid>
            <Grid item>
              <FormControl error={privacy.error}>
                <FormGroup sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                }}
                >
                  <Checkbox checked={privacy.checked}
                            onClick={handleCheck}/>
                  <FormLabel>
                    Ich habe die Inhalte der <a style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    fontWeight: 'bold',
                  }} href="/datenschutz">Datenschutzrichtlinie</a> zur
                    Verarbeitung meiner personenbezogenen Daten zur Kenntnis
                    genommen.*
                  </FormLabel>
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Button variant={'contained'} endIcon={<Send/>}
                    onClick={handleSubmit}>Absenden</Button>
          </Grid>
        </Grid>

      </Container>
  );
}