import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Alert, Grow, Snackbar} from '@mui/material'

import {uiMessage} from '../../reducer/UiReducer'
import {clearMsg} from '../../actions/uiActions'

export default function UiMessage() {

  // @ts-ignore
  const uiMessage: uiMessage = useSelector(state => state.Ui)
  const dispatch = useDispatch()
  console.log(uiMessage)

  const handleClose = () => {
    dispatch(clearMsg())
  }

  if (uiMessage.type !== null) {
    return (
        <Snackbar open={uiMessage?.content.length > 0}
                  anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                  autoHideDuration={5000}
                  message={uiMessage.content}
        >
          {/*// @ts-ignore*/}
          <Alert severity={uiMessage.type}
                 sx={{width: '100%', background: 'green', color: 'white'}}
                 onClose={handleClose}
                 onClick={handleClose}>
            {uiMessage.content ?? ''}
          </Alert>
        </Snackbar>
    )
  }
  else {
    return <div/>
  }
}