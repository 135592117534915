import React, {useState} from 'react';
import {IconButton, Modal, styled} from '@mui/material';
import {Close} from '@mui/icons-material';
import classes from './image.module.css';

export interface ImageProps {
  src: string,
  alt?: string,
  lightbox?: boolean,
}

const LightBox = styled(Modal)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
}));

const CloseIcon = styled(IconButton)(({theme}) => ({
  position: 'fixed',
  top: theme.spacing(0),
  right: theme.spacing(0),
  color: '#fff',
  zIndex: 10,
}));

export default function Image({src, alt = '', lightbox = true}: ImageProps) {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (lightbox) {
    return (
        <React.Fragment>
          <LightBox open={open} onClose={handleClose}>
            <React.Fragment>
              <CloseIcon onClick={handleClose}>
                <Close/>
              </CloseIcon>
              <img className={classes.image} src={src} alt={alt}/>
            </React.Fragment>
          </LightBox>
          <img className={classes.image} src={src} alt={alt}
               onClick={handleOpen}/>
        </React.Fragment>

    );
  }

  return (
      <img src={src} alt={alt}/>
  );

}

