import {UI_ACTIONS} from '../actions/ACTION_TYPES'

export interface uiMessage {
  type: 'error' | 'success' | 'info' | null,
  content: String,
}

const initialState: uiMessage = {
  type: null,
  content: '',
}

interface action {
  type: String,
  payload: uiMessage
}

export default function UiReducer(state = initialState, action: action) {
  switch (action.type) {
    case UI_ACTIONS.SET_MESSAGE: {
      return action.payload
    }
    case UI_ACTIONS.CLEAR_MESSAGES: {
      return initialState
    }
    default:
      return state
  }
}