import PopUp from './PopUpReducer'
import Ui from './UiReducer'
import {applyMiddleware, combineReducers, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunk from 'redux-thunk'

const combinedReducer = combineReducers({
  PopUp,
  Ui,
})

export const reduxStore = createStore(combinedReducer,
    composeWithDevTools(applyMiddleware(thunk)))