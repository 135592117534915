import {Box, Container, Stack, styled, Typography} from '@mui/material';

const PrimaryBox = styled(Box)(({theme}) => ({
  background: theme.palette.primary.main,
  borderTop: `${theme.spacing(1)} solid ${theme.palette.secondary.main}`,
}));

const Footer = () => {
  return (
      <PrimaryBox sx={{py: 1}}>
        <Container>
          <Stack direction={'row'} alignItems={'center'}
                 justifyContent={'space-between'}>
            <Box>
              <Typography variant={'body1'}
                          sx={{color: '#fff'}}>Befundportal</Typography>

            </Box>
            <Stack justifyContent={'flex-end'} spacing={3} direction={'row'}
                   alignItems={'center'}
                   sx={{
                     py: 1,
                     'a': {
                       textDecoration: 'none',
                       color: '#fff',
                     },
                   }}>
              <Typography
                  href={'https://rh-dt.de/impressum/'} target="_blank" variant={'h6'}
                  component={'a'}>Impressum</Typography>
              <Typography
                  href={'https://rh-dt.de/datenschutz/'}
                  target="_blank"
                  variant={'h6'}
                  component={'a'}>Datenschutz</Typography>
            </Stack>
          </Stack>
        </Container>
      </PrimaryBox>
  );
};

export default Footer;