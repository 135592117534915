import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import {Button, Grid, Container} from '@mui/material';
import downloadImg from './download.png';
import Image from '../components/shared/Image';

export default function BilderFAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      };

  return (
      <Container className={'faqSection'}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant={'h4'} variantMapping={{'h4': 'h2'}}
                        sx={{color: '#ffffff', m: 0}}>Aufnahmen /
              Bilder</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <div>
                <Accordion expanded={expanded === 'panel4'}
                           onChange={handleChange('panel4')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wie kann ich die Bilder auf meinem eigenen Rechner
                      anschauen oder speichern?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Durch die Download-Funktion wird im lokalen Verzeichnis
                      des Rechners eine
                      ZIP-Datei abgelegt. Diese kann entweder lokal gespeichert
                      oder entpackt werden,
                      oder an einen Arzt ihres Vertrauens weitergeleitet werden.
                      Wir empfehlen aber
                      die Weitergabe des Zugangs-Codes an Ihren Arzt. Der
                      Zugangs-Code ist durch ihr
                      Geburtsdatum vor ungewünschtem Zugriff gesichert.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel1'}
                           onChange={handleChange('panel1')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wie kann ich meine Bilder im Dicom-Format herunterladen?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Image src={downloadImg}/>
                    <Typography sx={{mb: 3}}>
                      Das Herunterladen ist nur bei Nutzung eines Computers
                      möglich. Bei den unter dem Reiter Dokumente gelisteten
                      Untersuchungen gibt es rechts drei vertikale Punkte.
                      Klicke Sie auf die drei Punkte, dann öffnet sich ein
                      Kontext-Menü, in dem Sie „Dicom-Download“ auswählen
                      können.
                    </Typography>

                    <Typography>
                      <strong>Bitte beachten:</strong> In Ihrem Internetbrowser
                      müssen dafür Pop-Ups für diese Seite aktiviert sein.
                    </Typography>
                    <Typography>
                      Alle Untersuchungsbilder werden in einer .zip-Datei
                      heruntergeladen, die mit
                      allen gängigen Betriebssystemen entpackt werden können.
                      Zum Anschauen der
                      Bilder
                      benötigen Sie einen DICOM Viewer. Diese Programme finden
                      Sie über jede
                      beliebige
                      Suchmaschine im Internet zum Download.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel9'}
                           onChange={handleChange('panel9')}
                >
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wie kann ich meine Bilder im PNG-Format herunterladen?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    Rechts neben den Bildern ist eine senkrechte Werkzeugleiste
                    mit der Schaltfläche „Herunterladen“ (Nur bei Nutzung auf
                    PC). Heruntergeladen wird jeweils das gewählte Bild.
                  </AccordionDetails>
                </Accordion>

              </div>
            </Container>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container>
              <div>
                <Accordion expanded={expanded === 'panel3'}
                           onChange={handleChange('panel3')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Wie kann ich meine Bilder auf meinem eigenen Rechner
                      ansehen?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Es stehen zwei Download-Funktionen zur Verfügung, beide
                      jedoch nur bei Nutzung eines PCs:
                      <ul>
                        <li>
                          Der Download der
                          Untersuchung im Dicom-Format ist für das ärztliche
                          Fachpersonal gedacht; hierzu benötigen Sie ein
                          geeignetes
                          Programm zum Betrachten der Bilder
                        </li>
                        <li>
                          Der Download des
                          gerade angezeigten Bildes in einem Foto-Format (.png).
                        </li>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'}
                           onChange={handleChange('panel5')}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                  >
                    <Typography sx={{flexShrink: 0}}>
                      Der Download der Bilder funktioniert nicht
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>Sollte sich der Download der Bilder nicht
                      aktivieren lassen, müssen sie
                      in Ihrem Internetbrowser für diese Seite die Anzeige von
                      Pop-ups aktivieren.
                    </Typography>
                  </AccordionDetails>
                </Accordion> <Accordion expanded={expanded === 'panel6'}
                                        onChange={handleChange('panel6')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                  <Typography sx={{flexShrink: 0}}>
                    Werden meine Daten weitergegeben?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Wir legen höchsten Wert auf den Schutz Ihrer Daten. Diese
                    werden sicher auf unseren eigenen Servern gespeichert, und
                    mit Ihren Zugangsdaten können Sie die Bilder in unserem
                    Originalarchiv ansehen. Eine Weitergabe an dritte erfolgt
                    nur dann, wenn dies ausdrücklich von Ihnen autorisiert
                    ist. <br/><br/>
                    Die Übertragung erfolgt über eine
                    sichere Datenverbindung und unter Einhaltung aller
                    gesetzlichen Vorgaben.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Container>
  );
}