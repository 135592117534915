import {createTheme, responsiveFontSizes} from '@mui/material'
import {defaultTheme} from './themes/defaultTheme'

export const createCustomTheme = (
    primaryColor: string | number, secondaryColor: string | number) => {
  return responsiveFontSizes(createTheme({
    ...defaultTheme,
    palette: {
      primary: {
        //@ts-ignore
        main: primaryColor,
      },
      secondary: {
        //@ts-ignore
        main: secondaryColor,
      },
    },
    typography: {
      h1: {
        //@ts-ignore
        color: primaryColor,
      },
      h2: {
        //@ts-ignore
        color: secondaryColor,
      },
      topline: {
        //@ts-ignore
        color: primaryColor,
        fontWeight: 'bold',
      },
      buttonTopline: {
        color: '#ffffff',
        fontWeight: 300,
        fontSize: '3.75rem',
      },
    },
  }))
}