import {POPUP_ACTIONS} from '../actions/ACTION_TYPES';

type POPUP_TYPES = {
  'newCode': 'get new login code popup',
};

interface action {
  type: String,
  payload: any,
}

export interface popUpState {
  open?: false,
  popUp: keyof POPUP_TYPES | null,
}

const initialState: popUpState = {
  open: false,
  popUp: 'newCode',
};

export default function PopUpReducer(state = initialState, action: action) {
  switch (action.type) {
    case POPUP_ACTIONS.SET_OPEN:
      return {
        ...state,
        open: true,
      };
    case POPUP_ACTIONS.SET_CLOSE:
      return {
        ...state,
        open: false,
      };
    case POPUP_ACTIONS.SET_CONTENT:
      return {
        ...state,
        popUp: action.payload,
      };
    default:
      return state;
  }
}